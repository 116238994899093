export default {
    name: "Campaign",
    created() {
        this.$nextTick(function () {
            this.CampaignDataLoad()
        })
    },
    mounted() {
    },
    data() {
        return {
            CampaignData: null
        };
    },
    methods: {
        CampaignDataLoad() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedCampaign",
                data: { token: "webkey" },
                success: (response) => {
                    console.log(response);
                    let arr = []
                    if (response.text.length > 0 && response.text[0].image != "") {
                        $(".image-show").css({ 'display': 'block' });
                        $.each(response.text, (i, c) => {
                            console.log(c);
                            arr[i] =   {
                                href: 'https://waran.rhbtradesmart.co.id/rhbresources/assets/eBook-waran-terstruktur.%20compressed.pdf',
                                image: c.image
                            }
                         
                        })
                        arr.push(
                            {
                                href: 'https://waran.rhbtradesmart.co.id/rhbresources/assets/ebookwaranterstruktur%20Vol.2%20(download).pdf',
                                image: 'https://waran.rhbtradesmart.co.id/rhbresources/assets/eBook Banner 2.png'
                            }
                        )
                        this.CampaignData = arr
                    } else {
                        $(".image-noshow").css({ 'display': 'block' });
                    }
                },
                error: (XMLHttpRequest) => {
                }
            });
        }
    }
};